import React from 'react'
import HeaderPaginas from '../../../components/headerPaginas'
import Layout from '../../../components/layout'
import Seo from '../../../components/SEO/SEO'
import "../../../styles/servicios.scss"
// import "../../../styles/cloud-robusto.scss"
import CtaServicios from '../../../components/ctaServicios'


export default function hostingWeb(props) {
    return (
        <Layout>
            <Seo pathname={props.location.pathname} />
            <div className="cabecera-servicio">
                <HeaderPaginas titulo="Hosting web" imagen="/img/cabeceras/hostingweb-bullhost.jpg" />
                <div className="intro-servicio">
                    <p>Nuestro servicio de alojamiento y cuentas de correo para proyectos web, desde el blog más sencillo hasta el ecommerce más potente.</p>
                </div>
            </div>
            <div className="cuerpo-servicio">
                <section className="limitador">
                    <h2>¿Necesitas un hosting básico?</h2>
                    {/* <p>Nuestros hosting web: desde el más básico y sencillo, hasta nuestro hosting securizado blindado para proteger tu proyecto web y tu dominio.</p> */}
                    <p className="cuerpo-servicio__parrafo-destacado">Hosting básicos pero muy superiores: gestionados por el mejor equipo.</p>
                    <div className="fila-cards-cpd-propio-caracteristicas">
                        <div className="card-cpd-propio-caracteristicas">
                            <div className="col-imagen" style={{ backgroundImage: "url(/img/Hosting-basico-gestionado-bullhost.jpg)" }}>
                                {/* <StaticImage src="../../../static/img/bullhost-cloud-robusto-cpd.jpg" alt="Cloud robusto" /> */}
                            </div>
                            <div className="col-texto">
                                <div>
                                    <h3>Panel de control sencillo</h3>
                                    <p>Hemos simplificado al máximo el panel de control para que puedas realizar cualquier tarea cotidiana, como dar de alta todas las cuentas de correo que necesites. Además, contarás con un potente antispam y antivirus.</p>
                                </div>
                                <div>
                                    <h3>Solo clientes VIP</h3>
                                    <p>Bullhost no trabaja con miles de clientes anónimos, sino que colabora codo a codo: todos nuestros clientes sois premium y os podemos poner cara. De una manera honesta, os daremos el servicio que necesitáis, ni más ni menos.</p>

                                </div>
                                <div>
                                    <h3>Hosting evolutivo</h3>
                                    <p>Si tus necesidades cambian, podrás mejorarlas o reducirlas. Siempre tendrás un gestor de cuenta que te recomendará qué producto te conviene. Porque no es lo mismo una web con WordPress que un e-commerce con Magento, por ejemplo.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <div className="limitador">
                </div> */}
                <section className="limitador">
                    <h2>¿Y si quiero un hosting seguro?</h2>
                    <p>Entonces lo que buscas es nuestro hosting securizado, el hosting para quien no pondría en riesgo su web o tienda online.</p>
                    <div className="fila-cards-cpd-propio-caracteristicas">
                        <div className="card-cpd-propio-caracteristicas">
                            <div className="col-texto">
                                <div>
                                    <h3>Dedicado</h3>
                                    <p>El hosting funcionará en una instancia dedicada, con su propio panel avanzado de administración. Es decir, tu web y todos los servicios asociados a tu dominio estarán aislados de otras webs y/o servicios.</p>
                                </div>
                                <div>
                                    <h3>Securizado</h3>
                                    <p>Con el dominio que alberga securizado: porque securizar un dominio te garantiza que todos los emails que mandas están siendo validados. De hecho, para securizar un dominio este debe contar con las correctas activaciones de los siguientes parámetros: SPF, DKIM, DMARC y DNS SEC.</p>
                                    <p>Y con certificado SSL parametrizado y otros elementos más.</p>

                                </div>
                                <div>
                                    <h3>Monitorizado con IA</h3>
                                    <p>Nuestro hosting securizado garantiza el escaneo de vulnerabilidades con periodicidad mensual para anticiparnos a lo que pueda ocurrir. Además, si quisieras que los errores o riesgos detectados sean solucionados por nuestro equipo de desarrollo, puedes contratar un mantenimiento web en <a href="https://bullhost.agency">bullhost.agency</a>.</p>
                                </div>
                                <div>
                                    <h3>Blindado</h3>
                                    <p>Todos tus datos se alojarán en nuestro propio dantacenter (CPD) ubicado en un bunker en Leioa. En nuestro CPD toda nuestra infraestructura está blindada con, entre otras medidas, un sistema de defensa perimetral específica de alto rendimiento, gracias a uno de los partners más potentes a nivel mundial: <a href="https://www.paloaltonetworks.es/">Palo Alto Networks.</a></p>
                                </div>
                            </div>
                            <div className="col-imagen" style={{ backgroundImage: "url(/img/hosting-securizado-bullhost.jpg)" }}>
                                {/* <StaticImage src="../../../static/img/bullhost-cloud-robusto-cpd.jpg" alt="Cloud robusto" /> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <CtaServicios/>
        </Layout>
    )
}
